<template>
  <div>
    <div class="header">
      <div class="head">
        <div class="logo">
          <router-link to="/"
            ><img src="../assets/images/logo2.png" alt=""
          /></router-link>
        </div>
        <div class="search-right1">
          <div class="search">
            <input
              type="text"
              v-model="keyInfo"
              class="input"
              placeholder="输入关键字 回车搜索"
            />
            <div class="serch-but" @click="searchInfo()">搜索</div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-search">
      <div class="nav-list">
        <div
          :class="[isActiveType == 0 ? 'item active' : 'item']"
          @click="changeAll()"
        >
          全部
        </div>
        <div
          v-for="(item, index) in navData"
          :key="index"
          :class="[item.id == isActiveType ? 'item active' : 'item']"
          @click="changetType(item.id)"
        >
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="wCenter">
      <!-- 时间筛选 -->
      <div class="date">
        <span class="choice">时间筛选:</span>
        <span
          :class="[isActive == 0 ? 'time-active' : 'time']"
          @click="searchTime('')"
          >最新</span
        >
        <span
          :class="[isActive == 1 ? 'time-active' : 'time']"
          @click="searchTime(1)"
          >一周内</span
        >
        <span
          :class="[isActive == 2 ? 'time-active' : 'time']"
          @click="searchTime(2)"
          >一个月内</span
        >
        <span
          :class="[isActive == 3 ? 'time-active' : 'time']"
          @click="searchTime(3)"
          >三个月内</span
        >
      </div>
      <!-- 全部内容 -->
      <div v-if="list.length > 0" class="list">
        <div
          v-for="(item_list, index_list) in list"
          :key="index_list"
          class="item"
          @click="jump(item_list.id, item_list.news_id, item_list.nav_id)"
        >
          <div class="item-title">
            <div class="type">{{ item_list.name }}</div>
            <div class="title" v-html="item_list.post_title"></div>
          </div>
          <div class="content">
            <div v-if="item_list.thumbnail !== ''" class="left">
              <img :src="item_list.thumbnail" alt="" />
            </div>
            <div class="con">
              <div class="post_content">{{ item_list.post_content }}</div>
              <div class="con-date">{{ item_list.published_time }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="nodata" v-else>
        <div><img src="../assets/images/nodata.png" alt="" /></div>
        <div class="nodata-title">暂无相关内容…</div>
      </div>
      <div
        v-if="page.total > 0"
        style="width: 454px; height: 64px; margin-left: 700px"
      >
        <Page
          :page="this.page.page"
          :pageSize="this.page.pageSize"
          :total="this.page.total"
          @change="changePage"
        />
      </div>
    </div>
    <!-- 分页 -->

    <!-- 底部 -->
    <div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import { getNav } from "@/http/api/home/index";
import { search } from "@/http/api/home/search";
import Foot from "../components/foot.vue";
import Page from "../components/page.vue";
export default {
  data() {
    return {
      navData: [],
      isActive: 0,
      isActiveType: 0,
      list: [],
      key: "",
      keyInfo: "",
      page: {
        page: 1,
        pageSize: 10,
        total: 10,
      },
      value: "",
      news_id: "",
    };
  },
  components: {
    Foot,
    Page,
  },
  watch: {
    $route: {
      handler: function (val) {
        this.getNav();
        var key = val.query.key;
        this.key = key;
        this.keyInfo = key;
        this.search();
      },
    },
  },
  created() {
    this.getNav();
    var key = this.$route.query.key;
    this.key = key;
    this.keyInfo = key;
    this.search();
  },
  methods: {
    search() {
      if (this.key == "") {
        alert("请输入搜索标题");
        return false;
      }
      search({
        keyword: this.key,
        type: this.isActive,
        page: this.page.page,
        news_id: this.news_id,
      }).then((res) => {
        if (res.data.data.data.length !== 0) {
          this.list = res.data.data.data.map((item) => {
            let key = this.key;
            if (item.post_title.indexOf(key) !== -1) {
              item.post_title = item.post_title.replace(
                key,
                `<font color='#c51010'>${key}</font>`
              );
            }
            return item;
          });
          this.page.page = res.data.data.current_page;
          this.page.total = res.data.data.total;
        } else {
          this.page.page = 1;
          this.page.total = 0;
          this.list = [];
        }
      });
    },
    getNav() {
      getNav().then((res) => {
        this.navData = res.data.data;
      });
    },
    searchTime(type) {
      this.isActive = type;
      this.search();
    },
    searchInfo() {
      this.page.page = 1;
      var url = "search?key=" + this.keyInfo;
      this.$router.push({ path: url });
    },
    jump(id, new_id, nav_id) {
      var url = "/info?id=" + id + "&news_id=" + new_id + "&nav_id=" + nav_id;
      let route = this.$router.resolve({ path: url });
      window.open(route.href, "_blank");
      // this.$router.push({ path: url });
    },
    changePage(page) {
      this.page.page = page;
      this.search();
    },
    changetType(id) {
      this.isActiveType = id;
      this.isActive = 0;
      this.news_id = id;
      this.search();
    },
    changeAll() {
      this.isActiveType = 0;
      this.isActive = 0;
      this.value = "";
      this.news_id = "";
      this.search();
    },
  },
};
</script>

<style lang="less" scoped>
.nodata {
  width: 100%;
  height: 550px;
  text-align: center;
  img {
    width: 184px;
    height: 130px;
    margin-top: 150px;
  }
  .nodata-title {
    font-size: 14px;
    font-weight: 400;
    color: #b0b4ba;
  }
}
.header {
  min-width: 1200px;
  height: 166px;
  .head {
    width: 1200px;
    display: flex;
    margin: 0 auto;
    height: 166px;
    .logo {
      width: 512px;
      height: 120px;
      margin-top: 23px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .search-right1 {
      width: 689px;
      height: 85px;
      margin-top: 58px;
      .search {
        width: 688px;
        height: 48px;
        display: flex;
        .input {
          width: 493px;
          height: 48px;
          display: inline-block;
          border: none;
          margin-left: 119px;
          border: 1px solid #dddddd;
          padding-left: 20px;
        }
        .input::-webkit-input-placeholder {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 19px;
          margin-left: 20px;
        }
        .serch-but {
          width: 77px;
          height: 48px;
          display: inline-block;
          background: linear-gradient(45deg, #0cc1ba, #008a9b);
          font-size: 17px;
          color: #ffffff;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
        }
      }
    }
  }
}
.nav-search {
  background: linear-gradient(180deg, #0b918b 6%, #076a81);
  .nav-list {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .item {
      width: 80px;
      height: 56px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      line-height: 56px;
      color: #ffffff;
      font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
      margin-left: 20px;
      z-index: 9999;
      position: relative;
      .submenu {
        width: 100px;
        display: none;
        height: auto;
        font-size: 12px;
        line-height: 32px;
        color: #666666;
        background: #ffffff;
        font-weight: 700;
      }
      .box {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
        background: #ffffff;
        width: 100px;
      }
    }
    .nav-drop {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: none;
    }
    .item:hover {
      background: linear-gradient(360deg, #ffffff, #b2d8da 99%);
      color: #005a69;
      cursor: pointer;
    }
    .item:hover .submenu {
      // background: red;
      display: block;
    }
    .item:hover .nav-drop {
      display: block;
    }
    .submenu:hover {
      color: #059e96;
    }
    .active {
      width: 80px;
      height: 56px;
      background: linear-gradient(360deg, #ffffff, #b2d8da 99%);
      color: #005a69;
    }
  }
}
.wCenter {
  padding: 0px 20px;
  .date {
    height: 60px;
    width: 100%;
    line-height: 80px;
    .choice {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
    }
    .time {
      font-size: 18px;
      margin-left: 20px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    .time-active {
      font-size: 18px;
      color: #c51010;
      margin-left: 20px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .list {
    width: 100%;
    height: auto;
    .item {
      cursor: pointer;
      margin-top: 20px;
      .item-title {
        display: flex;
        height: 33px;
        .type {
          // width: 92px;
          padding: 2px;
          height: 33px;
          font-size: 20px;
          font-weight: 500;
          background: #059e96;
          text-align: center;
          line-height: 33px;
          color: #ffffff;
        }
        .title {
          width: 1000px;
          font-size: 24px;
          color: #333333;
          padding-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .content {
        background-color: white;
        display: flex;
        .left {
          width: 190px;
          height: 127px;
          margin-top: 19px;
          padding-right: 20px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .con {
          margin-top: 19px;
          height: 127px;
          .post_content {
            width: 990px;
            height: auto;
            // padding: 0px 21px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-size: 20px;
            color: #666666;
          }
          .con-date {
            width: 990px;
            height: 24px;
            // padding: 0px 21px;
            margin-top: 10px;
            font-size: 18px;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
