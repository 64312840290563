import request from '../../index'



export function search(params) {
    return request ({
        method: 'post',
        url: '/portal/search/index',
        data: params
    })
}